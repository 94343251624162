.input__field {
    @apply flex flex-col gap-2 flex-1
}
.input__field.inline {
    @apply flex-row items-center mt-4 mb-4
}

.input__field.inline > label, .input__field.inline > div {
    @apply flex-1
}
.input__field_label.large {
    @apply text-lg font-medium font-montserrat text-gray-800 dark:text-gray-200
}

.input__field_label.normal {
    @apply text-base font-medium font-montserrat text-gray-800 dark:text-gray-200
}

.input__field_input,.input__field_textarea , .input__field .react-select {
    @apply border border-gray-300 text-gray-500 p-2 rounded-md  transition duration-150 ease-in-out
    focus:outline-none focus:ring-0 focus:ring-blaze-500 focus:border-blaze-500
    active:outline-none active:ring-0 active:ring-blaze-500 active:border-blaze-500
    hover:outline-none hover:ring-0 hover:ring-blaze-500 hover:border-blaze-500
    shadow-sm w-full;
}

.input__field_textarea {
    @apply min-h-[150px]
}
.input__field .react-select__option {
    @apply hover:bg-blaze-50 active:bg-gray-50 text-gray-500
}

.input__field .react-select__option[aria-selected=true] {
    @apply bg-blaze-50 text-blaze-500
}


.input__field_input.error, .input__field_textarea.error {
    @apply border-red-500 focus:ring-red-500 focus:border-red-500;
}

.input__field_input:disabled {
    @apply bg-gray-200 border-gray-300 text-gray-500 cursor-not-allowed;
}
.input__fieldset {
    @apply flex flex-col gap-2 mb-4;
}

.input__fieldset.group {
    @apply flex-col md:flex-row gap-4 mb-0 flex-1;
}

.input__field_checkbox {
    @apply flex flex-col gap-2
}

.input__field_checkbox > label {
    @apply flex flex-row gap-2 items-center  text-base font-medium font-montserrat text-gray-700
}

.input__checkbox {
    @apply w-4 h-4    appearance-none border border-gray-300 transition duration-150 ease-in-out rounded-sm
    focus:outline-none  focus:ring-blaze-500 focus:border-blaze-500 shrink-0
    checked:bg-blaze-500
    checked:border-0
    checked:ring-0
    focus:ring-0
    shadow-sm
    relative
}

.input__checkbox:checked:after {
    content: '';
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);

    @apply w-[7px] h-3.5 absolute text-white top-[0px] left-1
}

.input__field .react-select {
    @apply p-0 text-gray-500 min-h-[42px]
}

.input__field .react-select.error {
    @apply border-red-500 focus:ring-red-500 focus:border-red-500;
}

.css-1dimb5e-singleValue {
    @apply text-gray-500
}

.input__suffix, .input__prefix {
    text-wrap: nowrap;
    padding-left: 10px;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    margin-left: -5px;
    background: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    @apply border-gray-300 border-[1px]
}

.input__field_input.error + .input__suffix {
    @apply border-red-500 text-red-500
}


.input__field .toggle > label {
    @apply h-6 relative inline-block
}

.input__field .toggle  [type="checkbox"] {
    @apply w-11 h-0 cursor-pointer inline-block;
    @apply focus:outline-0 dark:focus:outline-0;
    @apply border-0 dark:border-0;
    @apply focus:ring-offset-transparent dark:focus:ring-offset-transparent;
    @apply focus:ring-transparent dark:focus:ring-transparent;
    @apply focus-within:ring-0 dark:focus-within:ring-0;
    @apply focus:shadow-none dark:focus:shadow-none;

    @apply after:absolute before:absolute;
    @apply after:top-0 before:top-0;
    @apply after:block before:inline-block;
    @apply before:rounded-full after:rounded-full;

    @apply after:content-[''] after:w-5 after:h-5 after:mt-0.5 after:ml-0.5;
    @apply after:shadow-md after:duration-100;

    @apply before:content-[''] before:w-10 before:h-full;
    @apply before:shadow-[inset_0_0_#000];

    @apply after:bg-white dark:after:bg-gray-50;
    @apply before:bg-gray-300 dark:before:bg-gray-600;
    @apply before:checked:bg-blaze-500 dark:before:checked:bg-blaze-500;
    @apply checked:after:duration-300 checked:after:translate-x-4;

    @apply disabled:after:bg-opacity-75 disabled:cursor-not-allowed;
    @apply disabled:checked:before:bg-opacity-40;
}

.input__field [type="radio"]
{
    @apply border-0 dark:border-0 ;
}
.input__field [type="radio"] + span {
    @apply ml-2
}

.required {
    @apply text-red-500 dark:text-red-200
}