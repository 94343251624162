.cat-range {
    @apply inline-flex items-center rounded-md bg-whiter p-1.5 dark:bg-meta-4;
}
.cat-range-selector {
    @apply rounded  py-1 px-3 text-xs font-medium text-black  hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white dark:hover:bg-boxdark
}

.cat-range-selector.active {
    @apply bg-white shadow-card
}

:is(.dark .apexcharts-text) {
    fill: #aeb7c0 !important;
}