.register-container {
    @apply flex flex-col justify-center flex-1;

}

.register-container > .register {
    @apply   rounded-[20px] shadow-lg py-[50px] px-[50px] border border-gray-200 bg-white
}

.steps {
    height:10px;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    @apply mb-8
}

.steps > .step {
    @apply flex-1 bg-gray-200 rounded-[2px];
}

.steps > .step.complete {
    @apply bg-blaze-500
}


.step-one-image {
    @apply  h-[155px] flex-1 object-cover w-full mt-8 flex rounded-[15px]
}
