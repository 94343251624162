.topBar {
    @apply justify-end flex
}

.section__title {
    @apply text-lg text-gray-800 font-semibold dark:text-white flex-1 text-nowrap
}

.section__strap {
    @apply mb-6 text-sm text-gray-500 dark:text-gray-100
}

.sessions {
    @apply grid grid-cols-1 gap-6
}

.sessions .session {
    @apply p-6 border-gray-200 border-[1px] flex gap-8 items-center
}

.sessions .session .actions {
    @apply flex flex-1 gap-4 flex-row justify-end items-center min-w-[20%]
}