@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply font-rubik overflow-x-hidden min-h-screen;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        @apply min-h-screen
    }
}

.auth, #root {
    @apply min-h-screen
}

.auth {
    @apply h-screen flex flex-col;
    background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),url(../public/bg.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.auth > .auth__container {
    @apply flex-1 flex flex-col lg:flex-row justify-center gap-[0]  lg:gap-[50px] xl:gap-[150px] 2xl:gap-[200px] container;
}

.auth__title {
    @apply font-bold text-3xl font-montserrat text-blue-950;
}

.auth__strapline {
    @apply font-normal text-lg mt-3 text-gray-500 font-rubik
}

.auth__right {
    @apply flex flex-col justify-center flex-1;
}

.auth__link {
    @apply font-bold text-blaze-500 hover:text-blaze-600 transition duration-150 ease-in-out
}

.btn__auth_primary {
    @apply w-full flex items-center justify-center py-[10px] px-[10px] rounded-lg mt-6 bg-blaze-500
    text-white text-lg font-montserrat
    transition duration-150 ease-in-out
    hover:bg-blaze-600
    focus:bg-blaze-600
    focus:outline-none focus:ring-0 focus:ring-blaze-600
    disabled:bg-blaze-50
    disabled:text-blaze-200
}

.btn__auth_primary.small, .btn__auth_back.small {
    @apply w-auto py-[5px] px-[10px] text-sm rounded-[5px]
}

.btn__auth_primary > svg {
    @apply mr-2
}

.btn__auth_back {
    @apply w-full flex items-center justify-center py-[10px] px-[15px] rounded-lg mt-6 bg-white
    text-blue-950 text-lg font-montserrat
    transition duration-150 ease-in-out
    border-gray-200
    border
    hover:bg-gray-50
    focus:bg-gray-50
    focus:outline-none focus:ring-0 focus:ring-gray-300
}

.auth-footer_links {
    @apply text-sm text-gray-500 mt-[25px] text-center
}

.auth-footer_links > a {
    @apply text-blaze-500 font-medium ml-2
}
.back-link {
    @apply text-blaze-500 dark:text-blaze-100 text-sm items-center
}

.saas-box {
    @apply rounded-sm border border-stroke bg-white px-5 pt-6 pb-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7
}

.badge-Pending,.badge-pending {
    @apply px-2 py-1 text-yellow-600 bg-yellow-100 border-[1px] border-yellow-500  rounded-[5px] w-[100px] text-center
}

.badge-Public,.badge-public {
    @apply px-2 py-1 text-green-600 bg-green-100 border-[1px] border-green-500  rounded-[5px] w-[100px] text-center
}

.badge-Appealed,.badge-appealed {
    @apply px-2 py-1 text-red-600 bg-red-100 border-[1px] border-red-500  rounded-[5px] w-[100px] text-center
}