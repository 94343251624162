.settings-link {
    @apply border-gray-200 border-[1px] p-4 rounded-md leading-tight text-gray-800
    hover:bg-gray-50 flex flex-row gap-3 items-center ;
}
.settings-link > svg {
    @apply p-3 rounded-full h-4 w-4  mr-3
}
.settings-link.red > svg {
    @apply bg-red-50 text-red-500
}

.settings-link.green > svg {
    @apply bg-green-50 text-green-500
}
.settings-link .section__title {
    @apply text-base font-montserrat
}

.settings-link .section__strap {
    @apply mb-0
}

.react-international-phone-input-container .react-international-phone-input {
    @apply  text-base p-3 rounded-r-md text-gray-500 h-auto border-gray-300  border-[1px] w-full appearance-none leading-tight  focus:ring-offset-0 focus:ring-0 focus:outline-blaze-500 focus:outline focus:outline-[1px];
    -moz-border-radius-topleft: 0;
    border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
}

.react-international-phone-country-selector-button {
    @apply p-2.5 h-auto rounded-l-md border-gray-300  border-[1px] border-r-0 appearance-none  focus:ring-offset-0 focus:ring-0 group-focus:outline-blaze-500 group-focus:outline group-focus:outline-[1px]
}

