.twofactor-container {
    @apply flex flex-col justify-center flex-1;

}

.twofactor-container > .twofactor {
    @apply   rounded-[20px] shadow-lg py-[50px] px-[50px] border border-gray-200 bg-white
}

.pincode-input-container {
    @apply flex flex-row gap-[10px] mt-4
}

.two-factor-form {
    @apply mt-6 flex flex-col
}
.pincode-input-text {
    @apply flex-1 !h-[60px] focus:!border-blaze-500 focus:ring-0 !m-0
}

.two-factor-form .resend {
    @apply text-sm text-gray-500 mb-6 text-left
}


.two-factor-form .resend a {
    @apply underline text-blue-950 font-montserrat font-bold ml-2 cursor-pointer active:text-blaze-500 focus:text-blaze-500
}