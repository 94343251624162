.calculator-space__header {
    @apply flex flex-col border-b-[1px] border-gray-100 mb-10
}

.calculator-space__header  > .title {
    @apply text-3xl font-bold font-montserrat text-gray-800 dark:text-gray-50 mb-2
}

.calculator-space__header > .strapline {
    @apply mb-6 text-sm text-gray-500 dark:text-gray-100
}