.login-container {
    @apply flex flex-col justify-center flex-1;

}

.login-container > .login {
    @apply   rounded-[20px] shadow-lg py-[50px] px-[50px] border border-gray-200 bg-white
}

.socials {
    @apply mt-6 mb-3
}

.socials > .socials__link {
    @apply flex items-center justify-center p-4 py-2 border-gray-200 border rounded-lg text-blue-950
    hover:bg-gray-50
    hover:border-gray-300
    focus:ring-0
    focus:ring-blaze-500 focus:border-blaze-500 shadow-sm focus:outline-none
    mb-4
}

.socials  .socials__link:last-of-type {
    @apply mb-0
}

.socials > .socials__link i {
    align-items: center;
    display: flex;
    @apply text-xl mr-2
}

.remember_row {
    @apply flex justify-between mb-4 mt-2
}

.remember_row  .input__field_checkbox > label {
    @apply text-sm font-medium text-gray-500
}

.remember_row .auth__link {
    @apply text-sm
}

.fa-google {
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    align-items: center;
    display: flex;
    @apply text-xl mr-2
}