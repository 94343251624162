/* Modal
   ========================================================================== */

.modal {
    --spacing: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 9999;
}

.modal__close {
    display: block;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal__inner {
    position: relative;
    width: calc(var(--spacing) * 36);
    max-width: 100%;
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 1.5);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;
    @apply bg-white dark:bg-boxdark
}

.modal__close-btn {
    position: absolute;
    top: var(--spacing);
    right: var(--spacing);
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
}

.modal__close-btn svg {
    width: var(--spacing);
    height: var(--spacing);
    fill: currentColor;
    opacity: 0.5;
    @apply dark:fill-white fill-current
}

.modal__close-btn:hover svg {
    opacity: 1;
}