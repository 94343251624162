.new-space__steps {
    @apply flex flex-col gap-0
}

.new-space__steps > .step {
    @apply py-5 border-[1px] border-l-0 border-r-0 flex flex-row items-center last-of-type:border-b-0
}
.new-space__steps > .step > .description > .title {
    @apply text-lg text-gray-800 font-semibold dark:text-white
}
.new-space__steps > .step > .description {
    @apply flex-1 min-w-[90%] flex-row gap-4 flex
}
.new-space__steps > .step > .description > .circle {
    @apply border rounded-full border-blaze-400 w-5 h-5 mt-2 bg-white
}

.new-space__header {
    @apply flex flex-col border-b-[1px] border-gray-100 mb-10
}

.new-space__header > .title, .new-space__subsection_title {
    @apply text-3xl font-bold font-montserrat text-gray-800 dark:text-gray-50 mb-2
}
.new-space__header > .strapline, .new-space__subsection_strap {
    @apply mb-6 text-sm text-gray-500 dark:text-gray-100
}

.new-space__steps > .step >  .btn__auth_primary {
    @apply flex-1 mt-0 py-[5px] self-center rounded-[5px]
}

.new-space__footer {
    @apply flex flex-row justify-between border-t-[1px] border-gray-100 mt-10 pt-0 pb-3
}

.new-space__subsection_content {
    @apply text-sm text-gray-800 dark:text-gray-200 mb-4 font-medium mt-[-15px]
}
.new-space__subsection_content > p:first-of-type {
    @apply mt-0
}
.new-space__subsection_content > p {
    @apply mt-4
}
.new-space__subsection_content > ul {
    @apply pl-4 mt-3 list-disc
}

.new-space__subsection {
    @apply pb-8 pt-8 border-b-[1px] border-gray-100
}

.new-space__subsection:first-of-type {
    @apply pt-0
}
.new-space__subsection:last-of-type {
    @apply border-b-0 pb-0
}

.availability__row {
    @apply flex flex-col md:flex-row gap-2 items-center py-4 min-h-[80px] text-gray-500 font-montserrat
}

.availability__row > * {
    @apply flex flex-1
}

.availability__row > *:first-child {
    @apply font-bold text-gray-800
}
.availability__row > *:first-child, .availability__row > *:nth-child(2) {
    @apply max-w-[10%] min-w-[10%]
}

.availability__row > *:nth-child(3) {
    @apply max-w-[25%] min-w-[25%]
}

.serviceRow {
    @apply mb-4
}