.separator {
    @apply flex items-center justify-between relative w-full my-4;
}

.circle {
    @apply w-3 h-3 bg-blaze-500 rounded-full;
}

.circle-left {
    @apply mr-1;
}

.circle-right {
    @apply ml-1;
}

.separator-text {
    @apply px-4 text-gray-600 bg-white z-10 ;
}

.separator::before {
    content: '';
    @apply absolute h-px bg-blaze-400;
    left: 1rem; /* Adjust based on circle size and spacing */
    right: 1rem; /* Adjust based on circle size and spacing */
    top: 50%;
    transform: translateY(-50%);
}