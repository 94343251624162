.spaces-list__item {
    @apply flex flex-col flex-1
}
.spaces-list__item > a {
    @apply flex flex-col rounded-[15px] overflow-hidden h-full
}
.spaces-list__item > a   .spaces-list__item_content {
    @apply py-4 px-4
}

.spaces-list__item > a {
    @apply   border-[1px] border-gray-100
}
.spaces-list__item > a   .spaces-list__item_content .content{
    @apply text-sm mt-2
}
.spaces-list__item > a   .spaces-list__item_content .title{
    @apply text-base
}
