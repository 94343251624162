.product-recommendation .search-container {
    @apply flex flex-col justify-center items-center relative mb-10 mt-8 w-full relative;
}
.product-recommendation .search-container > .search-question {
    @apply absolute left-[10px] top-0 bottom-0 flex items-center justify-center ml-6 lg:ml-4;
}

.product-recommendation .search-container > .search-question > svg {
    @apply fill-blaze-500 w-[30px] h-[30px];
}

.product-recommendation .dictaphone {
    @apply absolute right-[30px] top-0 bottom-0 flex items-center justify-center;
}

.product-recommendation .search-field {
    @apply bg-white h-[78px] leading-[28px] text-[16px] w-full  px-16 shadow-xl  focus:border-blaze-300 hover:border-blaze-300 focus:outline-none hover:cursor-pointer rounded-lg border-gray-300 border-solid border-[1px];
}

.product-recommendation .pulse-trans {
    border-radius: 50%;
    @apply cursor-pointer w-auto h-auto p-[5px] bg-transparent
}

.product-recommendation .pulse {
    @apply bg-blaze-600 w-auto h-auto p-[5px];
    border-radius: 50%;
    margin: 10px;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}


.product-recommendation .pulse svg {
    @apply text-white fill-white ;
}

.product-recommendation .pulse-trans svg {
    @apply text-white fill-blaze-500 w-[30px] h-[30px];
}
.product-recommendation .chat-bubble {
    padding:16px 28px;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomleft: 2px;
    border-radius: 20px;
    border-bottom-left-radius: 2px;
    display:inline-block;
}
.product-recommendation .typing {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 17px;
}
.product-recommendation  .typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #8a8a8a;
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
}
.product-recommendation .typing .dot:nth-child(1) {
    animation-delay: 200ms;
}
.product-recommendation .typing .dot:nth-child(2) {
    animation-delay: 300ms;
}
.product-recommendation .typing .dot:nth-child(3) {
    animation-delay: 400ms;
}
.product-recommendation .typing .dot:last-child {
    margin-right: 0;
}

@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color:#6CAD96;
    }
    28% {
        transform: translateY(-7px);
        background-color:#9ECAB9;
    }
    44% {
        transform: translateY(0px);
        background-color: #B5D9CB;
    }
}
.product-recommendation .new-question {
    @apply container flex w-full justify-end items-center mb-6 mt-4
}

.product-recommendation .example-prompts {
    @apply flex mx-auto container flex-col lg:flex-row flex-wrap px-6 lg:px-0;
}

.product-recommendation .example-prompts-column-container {
    @apply flex flex-col w-full lg:w-2/6 p-3
}

.product-recommendation .example-prompts-column {
    @apply flex flex-col w-full bg-gray-50 p-3 rounded-md flex-1;
}

.product-recommendation .idea-prompt {
    @apply bg-blaze-50 hover:bg-blaze-100 p-3 mb-3 w-full inline-flex text-left rounded-sm text-blaze-900 cursor-pointer rounded-md flex-1 border-0 items-center
}
.product-recommendation .idea-prompt:hover {
    @apply bg-blaze-100;
}
.product-recommendation .idea-prompt svg {
    @apply fill-none stroke-blaze-600 stroke-[3px] w-[25px] min-w-[25px] mr-4;
}
.product-recommendation .idea-prompt:hover svg {
    @apply  stroke-blaze-500
}
.product-recommendation .trending {
    @apply fill-none stroke-orange-300 stroke-[4px] w-[40px] mr-4
}

.product-recommendation .search-title .text {
    @apply flex flex-col text-left;
}

.product-recommendation .search-title .text h4 {
    @apply font-semibold text-gray-600 text-[14px] mb-0;
}

.product-recommendation .search-title .text p {
    @apply font-normal text-gray-400 text-xs mb-4;
}
