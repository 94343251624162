#dashHeader {
    @apply sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none
}

.user-dropdown {
    @apply relative
}

.user-dropdown__link {
    @apply flex items-center gap-4
}

.user-dropdown__dropdown {
    @apply absolute right-0 mt-4 flex w-[15rem] flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark;
}

.user-dropdown__dropdown > ul {
    @apply flex flex-col gap-5 border-b border-stroke px-6 py-7 dark:border-strokedark
}

.user-dropdown__dropdown > ul > li > a {
    @apply flex items-center gap-3 text-sm font-medium duration-300 ease-in-out hover:text-blue-950 lg:text-base
}

.user-dropdown__dropdown button {
    @apply flex items-center gap-3 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-blue-950 lg:text-base
}